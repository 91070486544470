import Image from 'next/image'
import { TabContent } from './constants'
import { cn } from '@/lib/utils'
import { TabClassNames } from '@/custom-components/tab-section/TabSectionGenerate'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import useResponsive from '@/hooks/responsive'
import { H3, H4 } from '@/v1/@core/typography/Typography'

interface TabContentProps {
  data: TabContent
  tabClassNames?: TabClassNames
}

const TabContent = ({ data, tabClassNames }: TabContentProps) => {
  const { mobile, tabLandscape } = useResponsive()

  return (
    <>
      <div className={cn('mt-6', tabClassNames?.parent_content_wrapper)}>
        {!!data?.title?.html && (
          <h2
            className={cn(
              'mb-2 px-5 text-xl font-bold leading-[28px] text-[#020626] lg:mb-4 lg:px-0 lg:text-[32px] lg:leading-[38px]',
              data?.title?.classes
            )}
          >
            {data?.title?.html}
          </h2>
        )}
        {!!data?.content?.html && (
          <div
            className={cn(
              'mb-2 px-5 font-medium text-[#004263] lg:mb-4 lg:px-0 lg:text-2xl lg:leading-[30px] [&_p]:text-[#004263]',
              data?.content?.classes
            )}
          >
            {data?.content?.html}
          </div>
        )}

        <div className={cn('grid grid-cols-1 gap-2 lg:grid-cols-2', tabClassNames?.content_wrapper)}>
          <div
            // data-aos={!tabLandscape && 'fade-up'}
            // data-aos-delay={!tabLandscape && 500}
            // data-aos-duration={!tabLandscape && 1500}
            className={cn(
              'order-2 col-span-2 px-2 pb-0 lg:order-1 lg:col-span-1 lg:p-4 lg:px-0 lg:pb-4',
              tabClassNames?.content
            )}
          >
            {!!data?.detail_content?.html && (
              <div className={cn('mb-2 text-gray-slate [&_p]:text-gray-slate', data.detail_content.classes)}>
                {data?.detail_content?.html}
              </div>
            )}
            {!!data?.detail?.length &&
              data?.detail?.map((item, index) => (
                <div key={index} className='mb-6 lg:last:mb-0'>
                  <div className='flex items-center gap-1'>
                    {!!item?.icon && <div className='h-8 w-8'> {item?.icon} </div>}
                    {!!item?.title?.html && (
                      <H4 className={cn('mb-[5px] text-[#314259] sm:mb-[10px]', item?.title?.classes)}>
                        {item?.title?.html}
                      </H4>
                    )}
                  </div>
                  {!!item?.content?.html && (
                    <div
                      className={cn(
                        '[&_p]:[#697A8D] font-medium text-[#697A8D] body-content sm:px-[5px]',
                        item?.content?.classes
                      )}
                    >
                      {item?.content?.html}
                    </div>
                  )}
                  {!!item?.button && (
                    <CallToActionButton
                      className='mt-6'
                      text={item?.button?.button?.text}
                      href={item?.button?.button.href}
                      icon
                    />
                  )}
                </div>
              ))}
          </div>
          <div
            className={cn(
              'order-1 col-span-2 px-2 pb-4 lg:order-2 lg:col-span-1 lg:p-4 lg:px-0 lg:pb-4',
              tabClassNames?.image_wrapper
            )}
          >
            <Image
              // data-aos={!tabLandscape && 'fade-up'}
              // data-aos-delay={!tabLandscape && 500}
              // data-aos-duration={!tabLandscape && 1500}
              src={data?.img}
              alt={data.altTxt ?? data?.label}
              className={cn('mx-auto', tabClassNames?.image)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TabContent
